import React, { useEffect } from 'react';
import clsx from 'clsx';

import { SessionProps } from '../../../../types';
import { useAppDispatch } from '../../../../store/utils/hooks';
import {
  isUserServiceExpert,
  isSessionFullyLoaded,
  shouldShowTroubleshooting,
  useIsDarkMode,
} from '../../../../utils';
import { storeEquipmentSession } from '../../../../store/slices/history.slice';
import { registerNewSession } from '../../../../store/slices/forceFeedback.slice';
import EquipmentSessionAnswer from './Answer/EquipmentSessionAnswer';
import Troubleshooting from './Troubleshooting/Troubleshooting';
import DashboardSessionPastJobs from './PastJobs/DashboardSessionPastJobs';
import Carousel from './Carousel/Carousel';
import SearchSuggestions from './SearchSuggestions/SearchSuggestions';
import styles from './DashboardSession.module.scss';

interface Props extends SessionProps {
  containerRef: HTMLDivElement | null;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DashboardSession: React.FC<Props> = ({ containerRef, ...props }) => {
  const { session, isMocking, httpUrl, logError } = props;
  const darkMode = useIsDarkMode();
  const userServiceExpert = isUserServiceExpert();
  const dispatch = useAppDispatch();
  const showTroubleshooting = shouldShowTroubleshooting(session);

  useEffect(() => {
    if (isSessionFullyLoaded(session) && !session.savedToHistory) {
      dispatch(
        storeEquipmentSession({
          logError,
          sessionId: session.id,
          mock: isMocking,
          baseUrl: httpUrl,
        }),
      );
      dispatch(
        registerNewSession({
          sessionId: session.id,
        }),
      );
    }
  }, [dispatch, session, httpUrl, isMocking]);

  return (
    <div
      className={clsx(
        userServiceExpert ? styles.container : styles.smallContainer,
      )}
    >
      <h2
        className={clsx(
          styles.title,
          session.allEquipmentTags.length > 0 && styles.titleWithTagsBelow,
        )}
        id={`session-${session.id}`}
      >
        {session.question}
      </h2>
      {!session.isGenericSearch && session.allEquipmentTags.length > 0 && (
        <div className={styles.tags}>
          {session.allEquipmentTags.map((tag) => (
            <div
              key={tag}
              className={clsx(styles.tag, darkMode && styles.darkMode)}
            >
              {getDisplayedTag(tag)}
            </div>
          ))}
        </div>
      )}
      <div className={styles.content}>
        <div className={styles.mainInfo}>
          <EquipmentSessionAnswer {...props} />
          {showTroubleshooting && <Troubleshooting {...props} />}
          <SearchSuggestions {...props} />
        </div>
        {userServiceExpert && (
          <div className={styles.additionalInfo}>
            <Carousel {...props} />
            <DashboardSessionPastJobs {...props} />
          </div>
        )}
      </div>
    </div>
  );
};

const getDisplayedTag = (fullName: string): string => {
  const tags = fullName.split('###');

  return tags[tags.length - 1];
};

export default DashboardSession;
