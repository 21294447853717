import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  FetchingStatus,
  isQuestionFault,
  isQuestionFeedback,
  Survey,
  SurveyDetails,
  SurveyRootCause,
} from '../../types';
import { ApiThunkParams, AppThunkConfig } from '../store';
import {
  closeSurvey,
  fetchSurveyQuestions,
  sendFaultSurveyAnswer,
  sendFeedbackSurveyAnswer,
} from '../../integration/surveys.api';
import { setSurveyToCompleted, updateSurvey } from './surveys.slice';
import { convertFromBase64 } from '../../utils';

export const SURVEY_DETAILS_KEY = 'surveyDetails';

export interface SurveyDetailsState {
  status: FetchingStatus;
  details: SurveyDetails | null;
  selectedEquipment: string | null;
  openedAt: number | null;
  timeSpentOnCurrentQuestion: number | null;
}

const initialState: SurveyDetailsState = {
  status: FetchingStatus.IDLE,
  details: null,
  selectedEquipment: null,
  openedAt: null,
  timeSpentOnCurrentQuestion: null,
};

interface GetSurveyDetailsParams {
  httpUrl: string;
  isMocking: boolean;
  survey: Survey;
}

interface GetSurveyDetailsResults {
  details: SurveyDetails;
  openedAt: number;
}

export const getSurveyDetails = createAsyncThunk<
  GetSurveyDetailsResults,
  GetSurveyDetailsParams,
  AppThunkConfig
>(
  'getSurveyDetails',
  async ({ httpUrl, isMocking, survey }, { signal, dispatch }) => {
    const { id, equipment_type, status, question_ids } = survey;
    dispatch(setSelectedEquipment(equipment_type));
    // finish passing fields from state

    const questions = await fetchSurveyQuestions({
      signal,
      surveyId: id,
      baseUrl: httpUrl,
      mock: isMocking,
      questionIds: question_ids,
    });

    return {
      details: {
        id,
        status,
        questions: questions.map((question) => {
          if (isQuestionFault(question)) {
            return {
              ...question,
              additional_information: isMocking
                ? question.additional_information
                : convertFromBase64(question.additional_information),
            };
          }
          if (isQuestionFeedback(question)) {
            return {
              ...question,
              article_content: isMocking
                ? question.article_content
                : convertFromBase64(question.article_content),
            };
          }
          console.error(`Unknown question type: ${question.type}`);
          return question;
        }),
        equipmentType: equipment_type,
      },
      openedAt: Date.now(),
    };
  },
);

interface AnswerFeedbackSurveyParams extends ApiThunkParams {
  questionId: string;
  answerId: string;
  additionalInformation: string;
  answered: boolean;
}

export const answerFeedbackSurvey = createAsyncThunk<
  void,
  AnswerFeedbackSurveyParams,
  AppThunkConfig
>('answerFeedbackSurvey', async (params, { dispatch, getState, signal }) => {
  const {
    surveyDetails: { details },
  } = getState();
  if (details === null) {
    return;
  }
  const { id, questions } = details;
  if (!params.answered) {
    const answeredQuestionsCount =
      questions.filter((question) => question.answered).length + 1; // State is 1 answer behind at this point, the next action dispatched will update it
    dispatch(
      updateSurvey({
        id,
        answeredQuestionsCount,
        totalQuestionsCount: questions.length,
      }),
    );
  }
  dispatch(
    updateFeedbackQuestion({
      id: params.questionId,
      articleContent: params.additionalInformation,
    }),
  );
  // TODO: introduce error handling
  await sendFeedbackSurveyAnswer({ ...params, signal, surveyId: id });
});

interface AnswerFaultSurveyParams extends ApiThunkParams {
  questionId: string;
  answerId: string;
  additionalInformation: string;
  answered: boolean;
  rootCauses: SurveyRootCause[];
}

export const answerFaultSurvey = createAsyncThunk<
  void,
  AnswerFaultSurveyParams,
  AppThunkConfig
>('answerFaultSurvey', async (params, { dispatch, getState, signal }) => {
  const {
    surveyDetails: { details },
  } = getState();
  if (details === null) {
    return;
  }
  const { id, questions } = details;
  if (!params.answered) {
    const answeredQuestionsCount =
      questions.filter((question) => question.answered).length + 1; // State is 1 answer behind at this point, the next action dispatched will update it
    dispatch(
      updateSurvey({
        id,
        answeredQuestionsCount,
        totalQuestionsCount: questions.length,
      }),
    );
  }
  dispatch(
    updateFaultQuestion({
      id: params.questionId,
      additionalInformation: params.additionalInformation,
      rootCauses: params.rootCauses,
    }),
  );
  // TODO: introduce error handling
  await sendFaultSurveyAnswer({ ...params, signal, surveyId: id });
});

export interface CompleteSurveyParams {
  mock: boolean;
  baseUrl: string;
  surveyId: string;
}

export const completeSurvey = createAsyncThunk<
  void,
  CompleteSurveyParams,
  AppThunkConfig
>('completeSurvey', async (params, { dispatch, signal }) => {
  dispatch(setSurveyToCompleted(params.surveyId));
  await closeSurvey({ ...params, signal });
  // TODO: log error
});

interface UpdateFeedbackQuestionParams {
  id: string;
  articleContent: string;
}

interface UpdateFaultQuestionParams {
  id: string;
  additionalInformation: string;
  rootCauses: SurveyRootCause[];
}

const surveyDetailsSlice = createSlice({
  name: SURVEY_DETAILS_KEY,
  initialState,
  reducers: {
    setSelectedEquipment: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        selectedEquipment: action.payload,
      };
    },
    updateFeedbackQuestion: (
      state,
      action: PayloadAction<UpdateFeedbackQuestionParams>,
    ) => {
      if (state.details === null) {
        return state;
      }

      return {
        ...state,
        details: {
          ...state.details,
          questions: state.details.questions.map((question) => {
            if (question.id !== action.payload.id) {
              return question;
            } else {
              return {
                ...question,
                answered: true,
                article_content: action.payload.articleContent,
              };
            }
          }),
        },
      };
    },
    updateFaultQuestion: (
      state,
      action: PayloadAction<UpdateFaultQuestionParams>,
    ) => {
      if (state.details === null) {
        return state;
      }

      return {
        ...state,
        details: {
          ...state.details,
          questions: state.details.questions.map((question) => {
            if (question.id !== action.payload.id) {
              return question;
            } else {
              return {
                ...question,
                answered: true,
                additional_information: action.payload.additionalInformation,
                root_causes: action.payload.rootCauses,
              };
            }
          }),
        },
      };
    },
    setTimeSpentOnCurrentQuestion: (state) => ({
      ...state,
      timeSpentOnCurrentQuestion: Date.now(),
    }),
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(getSurveyDetails.pending, (state) => {
        state.status = FetchingStatus.PENDING;
        state.details = null;
      })
      .addCase(getSurveyDetails.fulfilled, (state, action) => {
        const { openedAt, details } = action.payload;
        state.status = FetchingStatus.SUCCESS;
        state.details = details;
        state.openedAt = openedAt;
      })
      .addCase(getSurveyDetails.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.status = FetchingStatus.ERROR;
        state.details = null;
      });
  },
});

const { actions, reducer } = surveyDetailsSlice;

export const {
  setSelectedEquipment,
  updateFeedbackQuestion,
  updateFaultQuestion,
  setTimeSpentOnCurrentQuestion,
  reset: resetSurveyDetails,
} = actions;

export const surveyDetailsReducer = reducer;
