import React, { useEffect } from 'react';
import clsx from 'clsx';

import { SessionProps, TroubleshootingStatus } from '../../../../../types';
import { useAppDispatch } from '../../../../../store/utils/hooks';
import TroubleshootingLanding from './Landing/TroubleshootingLanding';
import TroubleshootingFinished from './Finished/TroubleshootingFinished';
import TroubleshootingStarted from './Started/TroubleshootingStarted';
import { getRelatedSymptoms } from '../../../../../store/actions/relatedSymptoms.actions';
import styles from './Troubleshooting.module.scss';

const Troubleshooting: React.FC<SessionProps> = (props) => {
  const dispatch = useAppDispatch();
  const { session, httpUrl, isMocking, logError } = props;
  const visible = session.troubleshootingVisible;

  useEffect(() => {
    if (session.equipmentType === null) {
      return;
    }
    const promise = dispatch(
      getRelatedSymptoms({
        logError,
        baseUrl: httpUrl,
        mock: isMocking,
        sessionId: session.id,
        equipmentType: session.equipmentType,
        confirmedSymptoms: session.troubleshootingSelectedSymptoms,
        // This field is only used for mock data
        initialSymptom: session.extractedSymptom || session.question,
      }),
    );

    return () => {
      promise.abort();
    };
  }, [
    dispatch,
    httpUrl,
    isMocking,
    session.id,
    session.equipmentType,
    session.troubleshootingSelectedSymptoms,
    session.question,
    session.extractedSymptom,
  ]);

  switch (session.troubleshootingStatus) {
    case TroubleshootingStatus.Idle:
      return (
        <div className={clsx(styles.container, visible && styles.visible)}>
          <TroubleshootingLanding {...props} />
        </div>
      );
    case TroubleshootingStatus.InProgressOpen:
    case TroubleshootingStatus.InProgressClosed:
      return (
        <div className={clsx(styles.container, visible && styles.visible)}>
          <TroubleshootingStarted {...props} />
        </div>
      );
    case TroubleshootingStatus.Finished:
      return (
        <div className={clsx(styles.container, visible && styles.visible)}>
          <TroubleshootingFinished {...props} />
        </div>
      );
    default:
      return null;
  }
};

export default Troubleshooting;
