import { EquipmentSearchResponse } from '../../types';
import { CustomersResponse } from '../../integration/customers.api';

export const equipmentSearchSeed: EquipmentSearchResponse = {
  tags: [
    {
      name: 'WT 8.0-167 DD',
      full_name: 'WT 8.0-167 DD',
      level: 1,
      input: [
        'WT 8.0-167 DD',
        'WT 8.0-167 DD###Version 1',
        'WT 8.0-167 DD###Version 2',
      ],
    },
    {
      name: 'Version 1',
      full_name: 'WT 8.0-167 DD###Version 1',
      level: 2,
      input: ['WT 8.0-167 DD', 'WT 8.0-167 DD###Version 1'],
    },
    {
      name: 'Version 2',
      full_name: 'WT 8.0-167 DD###Version 2',
      level: 2,
      input: ['WT 8.0-167 DD', 'WT 8.0-167 DD###Version 2'],
    },
    {
      name: 'WT 14-236 DD',
      full_name: 'WT 14-236 DD',
      level: 1,
      input: ['WT 14-236 DD'],
    },
    {
      name: 'WT 3.x-103',
      full_name: 'WT 3.x-103',
      level: 1,
      input: ['WT 3.x-103'],
    },
    {
      name: 'WT 3.x-137',
      full_name: 'WT 3.x-137',
      level: 1,
      input: ['WT 3.x-137'],
    },
    {
      name: 'WT 2-B Energy 2B6',
      full_name: 'WT 2-B Energy 2B6',
      level: 1,
      input: ['WT 2-B Energy 2B6'],
    },
  ],
};

export const customerSearchSeed: CustomersResponse = {
  customers: [
    {
      id: 'customer1',
      name: 'UK Onshore WF',
      city: 'London',
    },
    {
      id: 'customer2',
      name: 'ESB (offshore)',
      city: 'Belfast',
    },
    {
      id: 'customer3',
      name: 'Landsvirkjun',
      city: 'Reykjavik',
    },
    {
      id: 'customer4',
      name: 'Borssele Sites I',
      city: 'Berlin',
    },
    {
      id: 'customer5',
      name: 'Hollandse Kust (noord)',
      city: 'Dublin',
    },
  ],
};
