export enum SurveyStatus {
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  Closed = 'CLOSED',
}

export enum SurveyType {
  Feedback = 'FEEDBACK',
  Fault = 'FAULT',
}

export interface Survey {
  id: string;
  type: SurveyType;
  equipment_type: string;
  status: SurveyStatus;
  first_question: string;
  question_ids: string[];
  answered_question_count: number;
  total_question_count: number;
  created_at: string;
}

export interface SurveyRootCause {
  root_cause: string;
  confirmed: boolean;
}

export interface Question {
  id: string;
  answer_id: string;
  type: SurveyType;
  text: string;
  tags: string[];
  answered: boolean;
  // These 2 fields only appear in mock data for now.
  question_job_code?: string;
  source_job_codes?: string[];
}

export interface FaultQuestion extends Question {
  type: SurveyType.Fault;
  root_causes: SurveyRootCause[];
  additional_information: string | null;
}

export const isQuestionFault = (
  question: Question,
): question is FaultQuestion => {
  return question.type === SurveyType.Fault;
};

export interface FeedbackQuestion extends Question {
  type: SurveyType.Feedback;
  article_content: string | null;
}

export const isQuestionFeedback = (
  question: Question,
): question is FeedbackQuestion => {
  return question.type === SurveyType.Feedback;
};

export interface SurveyDetails {
  id: string;
  equipmentType: string;
  status: SurveyStatus;
  questions: Question[];
}
