import React from 'react';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../../../store/utils/hooks';
import { ReactComponent as ArrowRight } from '../../../../assets/arrow-right.svg';
import SmartLoopQuestionSearch from '../../../Inputs/QuestionSearch/SmartLoopQuestionSearch';
import { AssistantProps, QuestionSource } from '../../../../types';
import { askQuestion } from '../../../../store/slices/streaming.slice';
import { setQuestion } from '../../../../store/slices/inputs.slice';
import { isDefined, isUserServiceExpert, useLanguage } from '../../../../utils';
import styles from './DashboardInputBox.module.scss';

interface Props extends AssistantProps {
  equipmentType: string | null;
  allEquipmentTags: string[];
}

const DashboardInputBox: React.FC<Props> = ({
  equipmentType,
  allEquipmentTags,
  ...props
}) => {
  const { wsUrl, httpUrl, isMocking, logInfo, logError, environment } = props;
  const { language } = useLanguage();
  const { conversationId } = useParams();
  const userServiceExpert = isUserServiceExpert();
  const dispatch = useAppDispatch();
  const { question } = useAppSelector((state) => state.inputs);

  const handleSearch = () => {
    const message = question.trim();
    if (message.length < 2 || !isDefined(conversationId)) {
      return;
    }
    dispatch(
      askQuestion({
        conversationId,
        wsUrl,
        httpUrl,
        isMocking,
        language,
        logInfo,
        logError,
        message,
        environment,
        equipmentType,
        allEquipmentTags,
        source: QuestionSource.FollowUpQuestion,
      }),
    );
    dispatch(setQuestion(''));
  };

  return (
    <div
      className={clsx(
        styles.container,
        !userServiceExpert && styles.centerContainer,
      )}
    >
      <SmartLoopQuestionSearch
        {...props}
        conversationId={conversationId}
        equipmentType={equipmentType}
        allEquipmentTags={allEquipmentTags}
      />
      <button className={styles.button} onClick={handleSearch}>
        <ArrowRight className={styles.icon} />
      </button>
    </div>
  );
};

export default DashboardInputBox;
