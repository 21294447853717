import api from './api';
import { ApiFetchParams, Equipment, EquipmentTypesResponse } from '../types';

const TRENDING_EQUIPMENTS_TO_SHOW = 10;

export const fetchTrendingEquipments = async ({
  baseUrl,
  signal,
  mock,
}: ApiFetchParams): Promise<Equipment[]> => {
  const { data } = await api.get<EquipmentTypesResponse>({
    mock,
    baseUrl,
    path: '/knowledge-base/api/v2/tags/search',
    queryParams: {
      limit: TRENDING_EQUIPMENTS_TO_SHOW,
    },
    config: { signal },
  });

  return data.tags.map((tag) => ({
    name: tag.name,
    fullName: tag.full_name,
    level: tag.level,
    input: tag.input,
  }));
};
