import {
  ApiFetchParams,
  ApiResponse,
  Question,
  Survey,
  SurveyRootCause,
  SurveyStatus,
} from '../types';
import api from './api';
import { convertToBase64 } from '../utils';

export interface SurveysResponse {
  data: Survey[];
  numberOfElements: number;
}

export const fetchSurveys = async ({
  baseUrl,
  mock,
  signal,
}: ApiFetchParams): Promise<Survey[]> => {
  const { data } = await api.get<SurveysResponse>({
    baseUrl,
    mock,
    path: '/knowledge-curation/api/v1/surveys',
    config: { signal },
    queryParams: {
      page: 1,
      size: 50,
    },
  });

  return data;
};

interface FetchSurveyDetailsParams extends ApiFetchParams {
  surveyId: string;
  questionIds: string[];
}

export const fetchSurveyQuestions = async ({
  baseUrl,
  surveyId,
  mock,
  questionIds,
  signal,
}: FetchSurveyDetailsParams): Promise<Question[]> => {
  return Promise.all(
    questionIds.map((questionId) =>
      fetchQuestion({
        baseUrl,
        mock,
        signal,
        questionId,
        surveyId,
      }),
    ),
  );
};

interface FetchQuestionParams extends ApiFetchParams {
  surveyId: string;
  questionId: string;
}

const fetchQuestion = async ({
  baseUrl,
  mock,
  signal,
  surveyId,
  questionId,
}: FetchQuestionParams): Promise<Question> => {
  const { data } = await api.get<ApiResponse<Question>>({
    baseUrl,
    mock,
    path: `/knowledge-curation/api/v1/surveys/${surveyId}/questions/${questionId}`,
    config: { signal },
  });

  return data;
};

interface ApiAnswerFeedbackSurveyParams extends ApiFetchParams {
  surveyId: string;
  questionId: string;
  answerId: string;
  additionalInformation: string;
}

export const sendFeedbackSurveyAnswer = async ({
  baseUrl,
  mock,
  signal,
  surveyId,
  questionId,
  answerId,
  additionalInformation,
}: ApiAnswerFeedbackSurveyParams): Promise<void> => {
  if (mock) {
    console.log(`Feedback answer updated for ${surveyId}:${questionId}`);
  } else {
    await api.put<void>({
      baseUrl,
      mock,
      path: `/knowledge-curation/api/v1/surveys/${surveyId}/questions/${questionId}/answers/${answerId}`,
      body: {
        id: questionId,
        article_content: convertToBase64(additionalInformation),
      },
      config: { signal },
    });
  }
};

interface ApiAnswerFaultSurveyParams extends ApiAnswerFeedbackSurveyParams {
  rootCauses: SurveyRootCause[];
}

export const sendFaultSurveyAnswer = async ({
  baseUrl,
  mock,
  signal,
  surveyId,
  questionId,
  additionalInformation,
  rootCauses,
}: ApiAnswerFaultSurveyParams): Promise<void> => {
  if (mock) {
    console.log(`Fault answer updated for ${surveyId}:${questionId}`);
  } else {
    await api.put<void>({
      baseUrl,
      mock,
      path: `/knowledge-curation/api/v1/surveys/${surveyId}/questions/${questionId}/answers`,
      body: {
        id: questionId,
        additional_information: convertToBase64(additionalInformation),
        root_causes: rootCauses,
      },
      config: { signal },
    });
  }
};

interface CloseSurveyParams extends ApiFetchParams {
  surveyId: string;
}

export const closeSurvey = async ({
  baseUrl,
  mock,
  signal,
  surveyId,
}: CloseSurveyParams): Promise<void> => {
  if (mock) {
    console.log(`Survey closed for ${surveyId}`);
  } else {
    await api.patch<void>({
      baseUrl,
      mock,
      path: `/knowledge-curation/api/v1/surveys/${surveyId}`,
      body: {
        status: SurveyStatus.Closed,
      },
      config: { signal },
    });
  }
};
