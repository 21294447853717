import { Base64 } from 'js-base64';

export const convertFromBase64 = (value: string | null): string | null => {
  if (value === null) {
    return null;
  }

  try {
    const decodedArray = Base64.toUint8Array(value);
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(decodedArray);
  } catch (error) {
    console.error('Error decoding Base64:', error);
    return null;
  }
};

export const convertToBase64 = (value: string | null): string | null => {
  if (value === null) {
    return null;
  }

  try {
    const encoder = new TextEncoder();
    const encodedArray = encoder.encode(value);
    return Base64.fromUint8Array(encodedArray);
  } catch (error) {
    console.error('Error encoding to Base64:', error);
    return null;
  }
};
