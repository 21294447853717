import api from './api';
import { ApiFetchParams, Equipment, EquipmentTypesResponse } from '../types';
import { delay } from '../utils';

const EQUIPMENTS_TO_SHOW = 10;

interface FetchEquipmentTypesParams extends ApiFetchParams {
  searchTerm?: string;
  limit?: number;
}

export const fetchEquipmentTypes = async ({
  searchTerm,
  limit,
  signal,
  mock,
  baseUrl,
}: FetchEquipmentTypesParams): Promise<Equipment[]> => {
  const { data } = await api.get<EquipmentTypesResponse>({
    mock,
    baseUrl,
    path: '/knowledge-base/api/v2/tags/search',
    queryParams: {
      name: searchTerm,
      limit: limit || EQUIPMENTS_TO_SHOW,
    },
    config: { signal },
  });

  return data.tags.map((tag) => ({
    name: tag.name,
    fullName: tag.full_name,
    level: tag.level,
    input: tag.input,
  }));
};

interface MutateEquipmentTypesParams extends ApiFetchParams {
  equipmentType: string;
}

export const createEquipmentType = async ({
  equipmentType,
  signal,
  mock,
  baseUrl,
}: MutateEquipmentTypesParams): Promise<void> => {
  if (mock) {
    await delay(1500);
    return;
  }
  return api.post<void>({
    mock,
    baseUrl,
    path: '/knowledge-base/api/v1/tags',
    body: {
      name: equipmentType,
    },
    config: { signal },
  });
};

export const deleteEquipmentType = async ({
  equipmentType,
  signal,
  mock,
  baseUrl,
}: MutateEquipmentTypesParams): Promise<void> => {
  if (mock) {
    await delay(1500);
    return;
  }
  return api.delete<void>({
    mock,
    baseUrl,
    path: '/knowledge-base/api/v1/tags',
    queryParams: {
      name: equipmentType,
    },
    config: { signal },
  });
};
