import React from 'react';

import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../store/utils/hooks';
import { dismissForceFeedback } from '../../../../../../../store/slices/forceFeedback.slice';
import { useTranslations } from '../../../../../../../utils';
import { ReactComponent as Arrow } from '../assets/arrow.svg';
import { SessionProps } from '../../../../../../../types';
import { ReactComponent as ThumbsUp } from '../assets/thumbs-up.svg';
import { ReactComponent as ThumbsDown } from '../assets/thumbs-down.svg';
import styles from './ForceFeedback.module.scss';

interface Props extends SessionProps {
  anchor: HTMLDivElement;
}

const ForceFeedback: React.FC<Props> = ({ anchor }) => {
  const {
    assistant: { feedback: translations },
  } = useTranslations();
  const dispatch = useAppDispatch();
  const { show } = useAppSelector((state) => state.forceFeedback);
  // Values are offset by the padding of the highlight box, since the anchor has no padding
  const top = anchor.getBoundingClientRect().top - 8;
  const left = anchor.getBoundingClientRect().left - 12;

  if (!show) {
    return null;
  }

  return (
    <>
      <div
        onClick={() => dispatch(dismissForceFeedback())}
        className={styles.backdrop}
      />
      <div
        className={styles.container}
        style={{ top: `${top}px`, left: `${left}px` }}
        onClick={() => dispatch(dismissForceFeedback())}
      >
        <p>{translations.prompt}</p>
        <ThumbsUp className={styles.thumbsIcon} />
        <ThumbsDown className={styles.thumbsIcon} />
      </div>
      <Arrow
        className={styles.arrow}
        style={{ top: `${top + 32}px`, left: `${left - 88}px` }}
        onClick={() => dispatch(dismissForceFeedback())}
      />
      <p
        className={styles.text}
        style={{ top: `${top}px`, left: `${left - 232}px` }}
        onClick={() => dispatch(dismissForceFeedback())}
      >
        {translations.forceFeedbackPrompt}
      </p>
    </>
  );
};

export default ForceFeedback;
