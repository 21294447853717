import { SurveysResponse } from '../../integration/surveys.api';
import {
  FaultQuestion,
  FeedbackQuestion,
  SurveyStatus,
  SurveyType,
} from '../../types';

export const surveysSeed: SurveysResponse = {
  data: [
    {
      id: 'survey1',
      type: SurveyType.Feedback,
      equipment_type: 'WT 8.0-167 DD',
      first_question: 'Pitch bearing failure.',
      status: SurveyStatus.Open,
      answered_question_count: 0,
      total_question_count: 7,
      created_at: '2024-02-13T09:00:00.000Z',
      question_ids: [
        'question1',
        'question2',
        'question3',
        'question4',
        'question5',
        'question6',
        'question7',
      ],
    },
    {
      id: 'survey2',
      type: SurveyType.Fault,
      equipment_type: 'WT 3.x-103',
      first_question: 'Irregular blade movement.',
      status: SurveyStatus.InProgress,
      answered_question_count: 2,
      total_question_count: 3,
      created_at: '2024-02-10T09:00:00.000Z',
      question_ids: ['question8', 'question9', 'question10'],
    },
    {
      id: 'survey3',
      type: SurveyType.Feedback,
      equipment_type: 'WT 3.x-137',
      first_question: 'Generator failure.',
      status: SurveyStatus.Closed,
      answered_question_count: 5,
      total_question_count: 5,
      created_at: '2024-02-05T09:00:00.000Z',
      question_ids: [
        'question8',
        'question9',
        'question10',
        'question11',
        'question12',
      ],
    },
  ],
  numberOfElements: 3,
};

export const questionSeeds: Array<FeedbackQuestion | FaultQuestion> = [
  {
    id: 'question1',
    answer_id: 'answer1',
    type: SurveyType.Feedback,
    text: 'Which of the following issues are common for WT 3.x-103?',
    tags: [],
    answered: false,
    article_content: null,
    question_job_code: '12323',
    source_job_codes: ['12938', '19212', '12123'],
  },
  {
    id: 'question2',
    answer_id: 'answer2',
    type: SurveyType.Feedback,
    text: "With which condition or issue is the symptom 'Irregular blade movement' primarily associated?",
    tags: [],
    answered: false,
    article_content: null,
    question_job_code: '13323',
    source_job_codes: ['13938', '13212', '13123'],
  },
  {
    id: 'question3',
    answer_id: 'answer3',
    type: SurveyType.Feedback,
    text: 'What often leads to the replacement of pitch bearings in wind turbines?',
    tags: [],
    answered: false,
    article_content: null,
    question_job_code: '14323',
    source_job_codes: ['14938', '14212', '14123'],
  },
  {
    id: 'question4',
    answer_id: 'answer4',
    type: SurveyType.Feedback,
    text: 'Which of the following issues are common for WT 3.x-103?',
    tags: [],
    answered: false,
    article_content: null,
    question_job_code: '12323',
    source_job_codes: ['12938', '19212', '12123'],
  },
  {
    id: 'question5',
    answer_id: 'answer5',
    type: SurveyType.Feedback,
    text: "With which condition or issue is the symptom 'Irregular blade movement' primarily associated?",
    tags: [],
    answered: false,
    article_content: null,
    question_job_code: '13323',
    source_job_codes: ['13938', '13212', '13123'],
  },
  {
    id: 'question6',
    answer_id: 'answer6',
    type: SurveyType.Feedback,
    text: 'What often leads to the replacement of pitch bearings in wind turbines?',
    tags: [],
    answered: false,
    article_content: null,
    question_job_code: '14323',
    source_job_codes: ['14938', '14212', '14123'],
  },
  {
    id: 'question7',
    answer_id: 'answer7',
    type: SurveyType.Feedback,
    text: 'Which of the following issues are common for WT 3.x-103?',
    tags: [],
    answered: false,
    article_content: null,
    question_job_code: '12323',
    source_job_codes: ['12938', '19212', '12123'],
  },
  {
    id: 'question8',
    answer_id: 'answer8',
    type: SurveyType.Fault,
    text: 'Which of the following issues are common for WT 3.x-103?',
    tags: [],
    answered: true,
    root_causes: [
      { root_cause: 'Irregular blade movement', confirmed: true },
      { root_cause: 'Generator failure', confirmed: true },
      { root_cause: 'Pitch bearing failure', confirmed: true },
    ],
    additional_information:
      'Another possible issues is: Vibration and oscillation.',
    question_job_code: '13323',
    source_job_codes: ['13938', '13212', '13123'],
  },
  {
    id: 'question9',
    answer_id: 'answer9',
    type: SurveyType.Fault,
    text: "With which condition or issue is the symptom 'Irregular blade movement' primarily associated?",
    tags: [],
    answered: true,
    root_causes: [
      { root_cause: 'Physical Damage', confirmed: true },
      { root_cause: 'Balancing', confirmed: true },
      { root_cause: 'Pitch System', confirmed: true },
      { root_cause: 'PCS malfunction', confirmed: false },
    ],
    additional_information:
      'PCS malfunction is more associated with equipment WT 8.0-167 DD.',
    question_job_code: '14323',
    source_job_codes: ['14938', '14212', '14123'],
  },
  {
    id: 'question10',
    answer_id: 'answer10',
    type: SurveyType.Fault,
    text: 'What often leads to the replacement of pitch bearings in wind turbines?',
    tags: [],
    answered: false,
    root_causes: [],
    additional_information: null,
    question_job_code: '12323',
    source_job_codes: ['12938', '19212', '12123'],
  },
  {
    id: 'question11',
    answer_id: 'answer11',
    type: SurveyType.Feedback,
    text: 'Which of the following issues are common for WT 3.x-103?',
    tags: [],
    answered: true,
    article_content: 'Another possible issues is: Vibration and oscillation.',
    question_job_code: '13323',
    source_job_codes: ['13938', '13212', '13123'],
  },
  {
    id: 'question12',
    answer_id: 'answer12',
    type: SurveyType.Feedback,
    text: "With which condition or issue is the symptom 'Irregular blade movement' primarily associated?",
    tags: [],
    answered: true,
    article_content:
      'PCS malfunction is more associated with equipment WT 8.0-167 DD.',
    question_job_code: '14323',
    source_job_codes: ['14938', '14212', '14123'],
  },
];
