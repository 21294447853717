import { isDefined } from '../utils';
import { customerSearchSeed, equipmentSearchSeed } from './seed/search.seed';
import { dashboardOpenJobsSeed } from './seed/dashboardOpenJobs.seed';
import { trendingCustomersSeed } from './seed/trendingCustomers.seed';
import {
  bladeFinalSeed,
  generatorFinalSeed,
  pitchFinalSeed,
} from './seed/predictions.seed';
import {
  bladeImagesSeed,
  generatorImagesSeed,
  pitchImagesSeed,
} from './seed/media.seed';
import { ApiResponse, RequestParams } from '../types';
import { getCustomerPastJobsSeed } from './seed/customerPastJobs.seed';
import { getCustomerOpenJobsSeed } from './seed/customerOpenJobs.seed';
import { getCustomerTrendingSymptomsSeed } from './seed/trendingSymptoms.seed';
import { getSuggestionsSeed } from './seed/suggestions.seed';
import { questionSeeds, surveysSeed } from './seed/surveys.seed';
import { getFtfrSeed } from './seed/ftfr.seed';
import { getMttrSeed } from './seed/mttr.seed';
import { getMtbvSeed } from './seed/mtbv.seed';
import { HistorySeed } from './seed/history.seed';
import { getSymptomsSearchSeed } from './seed/symptoms.seed';
import { getExtractedSymptomSeed } from './seed/extractedSymptom.seed';
import { documentsSeed } from './seed/documents.seed';
import { userProfileSeed } from './seed/userProfile.seed';
import { getEquipmentPastJobsSeed } from './seed/equipmentPastJobs.seed';

const isTestEnv = process.env.NODE_ENV === 'test';
const RESPONSE_DELAY_MS = isTestEnv ? 50 : 1500;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type MockHandler = (params: RequestParams) => ApiResponse<any>;

export const handleMockRequest = async (
  params: RequestParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const handler = serverHandlers[params.path];
  if (!isDefined(handler)) {
    console.error(`Handler not found for path ${params.path}`);
    return;
  }
  const result = handler(params);

  return new Promise((resolve) => {
    window.setTimeout(() => {
      resolve(result);
    }, RESPONSE_DELAY_MS);
  });
};

const serverHandlers: Record<string, MockHandler> = {
  '/knowledge-base/api/v2/tags/search': () => ({
    data: equipmentSearchSeed,
  }),
  '/domain-model/api/v1/customers/actions/search': () => ({
    data: customerSearchSeed,
  }),
  '/troubleshooting/api/v2/symptoms/actions/search': ({ queryParams = {} }) => {
    const { name } = queryParams as Record<string, string>;

    return {
      data: {
        symptoms: getSymptomsSearchSeed(name),
      },
    };
  },
  '/domain-model/api/v1/jobs': ({ queryParams = {} }) => {
    const { customerId, jobIds } = queryParams as Record<string, string>;

    if (isDefined(customerId)) {
      return {
        data: getCustomerPastJobsSeed(customerId),
      };
    }

    if (isDefined(jobIds)) {
      return {
        data: getEquipmentPastJobsSeed(jobIds.split(',')),
      };
    }

    return {
      data: [],
    };
  },
  '/fault/api/v1/faults': ({ queryParams = {} }) => {
    const { customerId } = queryParams as Record<string, string>;

    if (isDefined(customerId)) {
      return {
        data: {
          faults: getCustomerOpenJobsSeed(customerId),
          meta: {
            totalElements: 4,
          },
        },
      };
    } else {
      return {
        data: {
          faults: dashboardOpenJobsSeed,
          meta: {
            totalElements: 10,
          },
        },
      };
    }
  },
  '/analytics/api/v1/metrics': ({ queryParams = {} }) => {
    const { type, customerId } = queryParams as Record<string, string>;

    switch (type) {
      case 'TopSymptoms':
        return {
          data: getCustomerTrendingSymptomsSeed(customerId),
        };
      case 'CustomersInFocus':
        return {
          data: trendingCustomersSeed,
        };
      case 'FirstTimeFixRate':
        return {
          data: getFtfrSeed(customerId),
        };
      case 'MeanTimeToResolution':
        return {
          data: getMttrSeed(customerId),
        };
      case 'MeanTimeBetweenVisits':
        return {
          data: getMtbvSeed(customerId),
        };
      default:
        throw new Error(
          `Unknown request type received on '/analytics/api/v1/metrics': ${type}`,
        );
    }
  },
  '/knowledge-base/api/v1/feedbacks': ({ body }) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { useful, comment, reason } = body as any;
    if (useful) {
      // Intentional use of console.log, we should not use the app logger here!
      console.log('POSITIVE feedback received.');
    } else {
      // Intentional use of console.log, we should not use the app logger here!
      console.log(
        `NEGATIVE feedback received. Reason: ${reason} Details: ${comment}`,
      );
    }

    return { data: null };
  },
  '/knowledge-base/api/v1/media-files': ({ queryParams }) => {
    const { jobIds } = queryParams as { jobIds: string[] };
    if (jobIds.some((jobId) => jobId === bladeFinalSeed.jobIds[0])) {
      return { data: bladeImagesSeed };
    } else if (jobIds.some((jobId) => jobId === generatorFinalSeed.jobIds[0])) {
      return { data: generatorImagesSeed };
    } else if (jobIds.some((jobId) => jobId === pitchFinalSeed.jobIds[0])) {
      return { data: pitchImagesSeed };
    } else {
      return { data: { items: [] } };
    }
  },
  '/knowledge-base/api/v1/rewritten-question': ({ queryParams }) => {
    const { question } = queryParams as { question: string };

    return { data: { question, answer: getSuggestionsSeed(question) } };
  },
  '/knowledge-base/api/v1/extracted-symptom': ({ queryParams }) => {
    const { question } = queryParams as { question: string };

    return { data: getExtractedSymptomSeed(question) };
  },
  '/knowledge-curation/api/v1/surveys': () => {
    return surveysSeed;
  },
  '/knowledge-curation/api/v1/surveys/survey1/questions/question1': () => {
    return { data: questionSeeds[0] };
  },
  '/knowledge-curation/api/v1/surveys/survey1/questions/question2': () => {
    return { data: questionSeeds[1] };
  },
  '/knowledge-curation/api/v1/surveys/survey1/questions/question3': () => {
    return { data: questionSeeds[2] };
  },
  '/knowledge-curation/api/v1/surveys/survey1/questions/question4': () => {
    return { data: questionSeeds[3] };
  },
  '/knowledge-curation/api/v1/surveys/survey1/questions/question5': () => {
    return { data: questionSeeds[4] };
  },
  '/knowledge-curation/api/v1/surveys/survey1/questions/question6': () => {
    return { data: questionSeeds[5] };
  },
  '/knowledge-curation/api/v1/surveys/survey1/questions/question7': () => {
    return { data: questionSeeds[6] };
  },
  '/knowledge-curation/api/v1/surveys/survey2/questions/question8': () => {
    return { data: questionSeeds[7] };
  },
  '/knowledge-curation/api/v1/surveys/survey2/questions/question9': () => {
    return { data: questionSeeds[8] };
  },
  '/knowledge-curation/api/v1/surveys/survey2/questions/question10': () => {
    return { data: questionSeeds[9] };
  },
  '/knowledge-curation/api/v1/surveys/survey3/questions/question8': () => {
    return { data: questionSeeds[7] };
  },
  '/knowledge-curation/api/v1/surveys/survey3/questions/question9': () => {
    return { data: questionSeeds[8] };
  },
  '/knowledge-curation/api/v1/surveys/survey3/questions/question10': () => {
    return { data: questionSeeds[9] };
  },
  '/knowledge-curation/api/v1/surveys/survey3/questions/question11': () => {
    return { data: questionSeeds[10] };
  },
  '/knowledge-curation/api/v1/surveys/survey3/questions/question12': () => {
    return { data: questionSeeds[11] };
  },
  '/knowledge-base/api/v1/sessions': () => {
    return { data: HistorySeed };
  },
  '/knowledge-base/api/v1/documents': () => {
    return {
      data: {
        items: documentsSeed,
        totalElements: documentsSeed.length,
      },
    };
  },
  '/knowledge-base/api/v1/profile-settings': () => {
    return { data: { settings: userProfileSeed } };
  },
};
