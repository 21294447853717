import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  DiagnosisNode,
  DiagnosisNodeType,
  DiagnosisQnaStep,
  FetchingStatus,
} from '../../types';
import { ApiThunkParams, AppThunkConfig } from '../store';
import { fetchDiagnoses } from '../../integration/diagnoses.api';
import { updateEquipmentSession } from '../slices/conversations.slice';
import { isDefined } from '../../utils';

export interface GetDiagnosesParams extends ApiThunkParams {
  sessionId: string;
}

export const getDiagnoses = createAsyncThunk<
  void,
  GetDiagnosesParams,
  AppThunkConfig
>(
  'getDiagnoses',
  async (
    { sessionId, baseUrl, mock, logError },
    { getState, dispatch, signal },
  ) => {
    const {
      conversations: { sessions },
    } = getState();
    const session = sessions.find((session) => session.id === sessionId);
    if (!isDefined(session) || session.equipmentType === null) {
      return;
    }
    dispatch(
      updateEquipmentSession({
        id: sessionId,
        diagnosisStatus: FetchingStatus.PENDING,
        diagnosisNodes: null,
        qnaSteps: null,
        resolvedRootCauses: null,
        rootCauses: null,
        openedRootCauseId: null,
      }),
    );
    try {
      const { allNodes, rootCauses } = await fetchDiagnoses({
        signal,
        baseUrl,
        mock,
        confirmedSymptoms: session.troubleshootingSelectedSymptoms,
        equipmentType: session.equipmentType,
      });
      const questions = allNodes.filter(
        (node) => node.type === DiagnosisNodeType.Question,
      );
      if (questions.length > 0) {
        dispatch(
          updateEquipmentSession({
            rootCauses,
            id: sessionId,
            diagnosisNodes: allNodes,
            diagnosisStatus: FetchingStatus.SUCCESS,
            qnaSteps: [getQnaStep(questions[0], allNodes)], // We assume that the first element is the first element in the chain of QnA.
          }),
        );
      } else {
        dispatch(
          updateEquipmentSession({
            rootCauses,
            id: sessionId,
            diagnosisNodes: allNodes,
            diagnosisStatus: FetchingStatus.SUCCESS,
            resolvedRootCauses: rootCauses,
          }),
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      logError(
        `Could not fetch diagnoses for session ${sessionId}. Reason: ${error?.message}`,
      );
      dispatch(
        updateEquipmentSession({
          id: sessionId,
          diagnosisNodes: null,
          diagnosisStatus: FetchingStatus.ERROR,
        }),
      );
    }
  },
);

export const getQnaStep = (
  questionNode: DiagnosisNode,
  allNodes: DiagnosisNode[],
): DiagnosisQnaStep => {
  if (questionNode.type !== DiagnosisNodeType.Question) {
    throw new Error('Wrong node type detected!');
  }

  return {
    question: questionNode.label,
    selectedAnswer: null,
    answers: questionNode.children.map((answerNodeId) => {
      const answerNode = allNodes.find((node) => node.id === answerNodeId);
      if (!isDefined(answerNode)) {
        throw new Error('Answer node not found!');
      }

      return {
        answer: answerNode.label,
        links: answerNode.children,
      };
    }),
  };
};
