import React from 'react';
import clsx from 'clsx';

import { useAppDispatch, useAppSelector } from '../../store/utils/hooks';
import { setActiveFilter } from '../../store/slices/inputs.slice';
import { useIsDarkMode, useTranslations } from '../../utils';
import styles from './ActiveFilterToggle.module.scss';
import { ActiveFilter } from '../../types';

const ActiveFilterToggle: React.FC = () => {
  const {
    assistant: { activeFilterToggle: translations },
  } = useTranslations();
  const darkMode = useIsDarkMode();
  const dispatch = useAppDispatch();
  const { activeFilter } = useAppSelector((state) => state.inputs);

  return (
    <div className={clsx(styles.container, darkMode && styles.darkMode)}>
      <div
        className={clsx(
          styles.option,
          darkMode && styles.darkMode,
          activeFilter === ActiveFilter.All && styles.selected,
        )}
        onClick={() => dispatch(setActiveFilter(ActiveFilter.All))}
      >
        {translations[ActiveFilter.All]}
      </div>
      <div
        className={clsx(
          styles.option,
          darkMode && styles.darkMode,
          activeFilter === ActiveFilter.Customer && styles.selected,
        )}
        onClick={() => dispatch(setActiveFilter(ActiveFilter.Customer))}
      >
        {translations[ActiveFilter.Customer]}
      </div>
      <div
        className={clsx(
          styles.option,
          darkMode && styles.darkMode,
          activeFilter === ActiveFilter.EquipmentType && styles.selected,
        )}
        onClick={() => dispatch(setActiveFilter(ActiveFilter.EquipmentType))}
      >
        {translations[ActiveFilter.EquipmentType]}
      </div>
    </div>
  );
};

export default ActiveFilterToggle;
