import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { AutocompleteInputChangeReason } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import debounce from 'lodash.debounce';

import { useAppDispatch, useAppSelector } from '../../../store/utils/hooks';
import {
  ApiCall,
  AssistantProps,
  FetchingStatus,
  AppRoute,
  MixpanelEventType,
} from '../../../types';
import {
  isDefined,
  trackWithMixpanel,
  useLanguage,
  useTranslations,
} from '../../../utils';
import { searchCustomers } from '../../../store/slices/inputs.slice';
import { Customer } from '../../../integration/customers.api';
import { useCurrentRoute } from '../../../utils';
import { getCustomerDetails } from '../../../store/actions/customerDetails.actions';
import { ApiThunkParams } from '../../../store/store';
import styles from './SmartLoopCustomerSearch.module.scss';

const SmartLoopCustomerSearch: React.FC<AssistantProps> = (props) => {
  const { isMocking, httpUrl, wsUrl, environment, logError, logInfo } = props;
  const { language } = useLanguage();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const { assistant: translations } = useTranslations();
  const { customers, customerSearchStatus } = useAppSelector(
    (state) => state.inputs,
  );
  const [customer, setCustomer] = useState<Customer | null>(null);

  const [fetchPromise, setFetchPromise] =
    useState<ApiCall<Customer[], ApiThunkParams>>();

  const getOptions = useCallback(
    debounce((value: string) => {
      // Abort previous request
      if (isDefined(fetchPromise)) {
        fetchPromise.abort();
      }
      const searchTerm = value.trim();
      if (searchTerm.length >= 2) {
        const promise = dispatch(
          searchCustomers({
            logError,
            searchTerm,
            mock: isMocking,
            baseUrl: httpUrl,
          }),
        );
        setFetchPromise(promise);
      }
    }, 300),
    [dispatch, fetchPromise, isMocking, httpUrl],
  );

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const handleInputChange = useCallback(
    (
      _: React.SyntheticEvent,
      value: string,
      reason: AutocompleteInputChangeReason,
    ) => {
      // Clicking away triggers reset - we do not want to do anything here
      if (reason === 'reset') {
        return;
      }
      if (!value) {
        setOpen(false);
      }
      setInputValue(value);
      getOptions(value);
      if (reason === 'clear') {
        setCustomer(null);
      }
    },
    [getOptions],
  );

  const handleSelect = (_: SyntheticEvent, customer: Customer | null) => {
    if (customer === null) {
      return;
    }
    setCustomer(customer);
    trackWithMixpanel({
      environment,
      event: {
        name: MixpanelEventType.CustomerSelected,
        properties: {
          customer: customer.id,
        },
      },
    });
    dispatch(
      getCustomerDetails({
        customer,
        language,
        isMocking,
        httpUrl,
        wsUrl,
        logError,
        logInfo,
      }),
    );
    navigate(`${AppRoute.Customer}/${customer.id}`, {
      replace: currentRoute === AppRoute.Customer,
    });
  };

  useEffect(() => {
    setInputValue(customer ? customer.name : '');
  }, [customer]);

  const noResultsFound =
    customerSearchStatus === FetchingStatus.SUCCESS && customers.length === 0;

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        if (inputValue || customers.length > 0) {
          setOpen(true);
        }
      }}
      onClose={() => setOpen(false)}
      popupIcon={null}
      onChange={handleSelect}
      value={customer}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      loadingText={translations.filterLoading}
      noOptionsText={noResultsFound && translations.filterNoResults}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={customers}
      loading={customerSearchStatus === FetchingStatus.PENDING}
      filterOptions={(x) => x}
      renderOption={(props, customer) => (
        <Box
          component="li"
          sx={{ color: (theme) => theme.palette.secondary.light }}
          {...props}
          key={customer.id}
        >
          <Typography className={styles.list}>
            {customer.name}
            {customer.city && (
              <span>
                <br />
                <span className={styles.city}>{customer.city}</span>
              </span>
            )}
          </Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          placeholder={translations.customerSearchPlaceholder}
          InputProps={{ ...params.InputProps, disableUnderline: true }}
        />
      )}
    />
  );
};

export default SmartLoopCustomerSearch;
