import React, { useState } from 'react';
import ReactQuill from 'react-quill';

import {
  AssistantProps,
  isQuestionFault,
  isQuestionFeedback,
  Question,
} from '../../../../types';
import { isDefined, useTranslations } from '../../../../utils';
import {
  answerFaultSurvey,
  answerFeedbackSurvey,
} from '../../../../store/slices/surveyDetails.slice';
import { useAppDispatch } from '../../../../store/utils/hooks';
import styles from './SurveyForm.module.scss';
import { FormControlLabel, FormGroup } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

interface Props extends AssistantProps {
  question: Question;
  visible: boolean;
}

const SurveyForm: React.FC<Props> = ({
  question,
  visible,
  httpUrl,
  isMocking,
  logError,
}) => {
  const {
    assistant: {
      curationHub: { surveyDetails: translations },
    },
  } = useTranslations();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState(() => {
    if (isQuestionFault(question) && question.additional_information !== null) {
      return question.additional_information;
    } else if (
      isQuestionFeedback(question) &&
      question.article_content !== null
    ) {
      return question.article_content;
    } else {
      return '';
    }
  });
  const [selectedRootCauses, setSelectedRootCauses] = useState<boolean[]>(
    () => {
      if (isQuestionFault(question)) {
        return question.root_causes.map((rc) => rc.confirmed);
      } else {
        return [];
      }
    },
  );

  const handleBlur = () => {
    if (value.length === 0) {
      return;
    }
    if (isQuestionFeedback(question)) {
      dispatch(
        answerFeedbackSurvey({
          logError,
          baseUrl: httpUrl,
          mock: isMocking,
          questionId: question.id,
          answerId: question.answer_id,
          additionalInformation: value,
          answered: question.answered,
        }),
      );
    }
    if (isQuestionFault(question)) {
      dispatch(
        answerFaultSurvey({
          logError,
          baseUrl: httpUrl,
          mock: isMocking,
          questionId: question.id,
          answerId: question.answer_id,
          additionalInformation: value,
          answered: question.answered,
          rootCauses: question.root_causes.map((rc, index) => ({
            ...rc,
            confirmed: selectedRootCauses[index],
          })),
        }),
      );
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checkBoxIndex: number,
  ) => {
    if (!isQuestionFault(question)) {
      return;
    }
    const updatedSelection = selectedRootCauses.map((prevValue, index) => {
      if (index === checkBoxIndex) {
        return event.target.checked;
      } else {
        return prevValue;
      }
    });
    dispatch(
      answerFaultSurvey({
        logError,
        baseUrl: httpUrl,
        mock: isMocking,
        questionId: question.id,
        answerId: question.answer_id,
        additionalInformation: value,
        answered: question.answered,
        rootCauses: question.root_causes.map((rc, index) => ({
          ...rc,
          confirmed: updatedSelection[index],
        })),
      }),
    );
    setSelectedRootCauses(updatedSelection);
  };

  if (!visible) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div>
        <h2 className={styles.text}>{question.text}</h2>
        {isQuestionFault(question) && (
          <>
            {question.root_causes.length > 0 && (
              <>
                <p className={styles.prompt}>{translations.prompt}</p>
                <div className={styles.rootCauses}>
                  <FormGroup>
                    {question.root_causes.map((rc, index) => (
                      <FormControlLabel
                        key={rc.root_cause}
                        label={rc.root_cause}
                        control={
                          <Checkbox
                            color="info"
                            checked={selectedRootCauses[index]}
                            onChange={(event) => handleChange(event, index)}
                          />
                        }
                      />
                    ))}
                  </FormGroup>
                </div>
              </>
            )}
            <p className={styles.detailsLabel}>
              {translations.moreInformation}
            </p>
          </>
        )}
        <div className={styles.details}>
          <ReactQuill
            theme="snow"
            value={value}
            onChange={setValue}
            onBlur={handleBlur}
            className={styles.editor}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [
                  { list: 'ordered' },
                  { list: 'bullet' },
                  { indent: '-1' },
                  { indent: '+1' },
                ],
                ['link', 'image'],
                ['clean'],
              ],
              // This fixes the editor planting redundant newlines before lists when jumping between steps (#126)
              // See GitHub issue: https://github.com/slab/quill/issues/2905
              clipboard: {
                matchVisual: false,
              },
            }}
          />
        </div>
      </div>
      <div className={styles.jobIdContainer}>
        {isDefined(question.question_job_code) &&
          question.question_job_code.length > 0 && (
            <p className={styles.jobIds}>
              {translations.questionJobIds(question.question_job_code)}
            </p>
          )}
        {isDefined(question.source_job_codes) &&
          question.source_job_codes.length > 0 && (
            <p className={styles.jobIds}>
              {translations.answerJobIds(question.source_job_codes)}
            </p>
          )}
      </div>
    </div>
  );
};

export default SurveyForm;
