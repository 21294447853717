export enum MixpanelEventType {
  CustomerSelected = 'CustomerSelected',
  EquipmentSelected = 'EquipmentSelected',
  DocumentClicked = 'DocumentClicked',
  RootCauseCopied = 'RootCauseCopied',
  RootCausePasted = 'RootCausePasted',
  DocumentSummaryCopied = 'DocumentSummaryCopied',
  CustomerSummaryCopied = 'CustomerSummaryCopied',
  ServiceHistorySymptomCopied = 'ServiceHistorySymptomCopied',
  SimilarServiceJobsSymptomCopied = 'SimilarServiceJobsSymptomCopied',
  QuestionAsked = 'QuestionAsked',
  OpenJobClicked = 'OpenJobClicked',
  CustomerOpenJobClicked = 'CustomerOpenJobClicked',
  BrowserNavigation = 'BrowserNavigation',
  SurveyOpened = 'SurveyOpened',
  SurveyRootCauseSelected = 'SurveyRootCauseSelected',
  SurveyDetailsProvided = 'SurveyDetailsProvided',
  SurveyQuestionAnswered = 'SurveyQuestionAnswered',
  SurveyCompleted = 'SurveyCompleted',
  SearchSuggestionClicked = 'SearchSuggestionClicked',
}

export enum QuestionSource {
  HomeSearch = 'HomeSearch',
  SearchExample = 'SearchExample',
  OpenJob = 'OpenJob',
  FollowUpQuestion = 'FollowUpQuestion',
  Suggestion = 'Suggestion',
}

interface BaseMixpanelEvent {
  name: MixpanelEventType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: Record<string, any>;
}

export interface CustomerSelectedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.CustomerSelected;
  properties: {
    customer: string;
  };
}

export interface EquipmentSelectedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.EquipmentSelected;
  properties: {
    equipmentType: string;
  };
}

export interface DocumentClickedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.DocumentClicked;
  properties: {
    name: string;
    link: string;
  };
}

export interface RootCauseCopiedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.RootCauseCopied;
  properties: {
    text: string;
  };
}

export interface RootCausePastedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.RootCausePasted;
  properties: {
    text: string;
  };
}

export interface DocumentSummaryCopiedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.DocumentSummaryCopied;
  properties: {
    text: string;
  };
}

export interface CustomerSummaryCopiedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.CustomerSummaryCopied;
  properties: {
    text: string;
  };
}

export interface ServiceHistorySymptomCopiedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.ServiceHistorySymptomCopied;
  properties: {
    text: string;
  };
}

export interface SimilarServiceJobsSymptomCopiedEvent
  extends BaseMixpanelEvent {
  name: MixpanelEventType.SimilarServiceJobsSymptomCopied;
  properties: {
    text: string;
  };
}

export interface QuestionAskedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.QuestionAsked;
  properties: {
    question: string;
    secondsSinceLastQuestion: number;
    source: QuestionSource;
  };
}

export interface OpenJobClickedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.OpenJobClicked;
  properties: {
    id: string;
    symptom: string;
  };
}

export interface CustomerOpenJobClickedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.CustomerOpenJobClicked;
  properties: {
    id: string;
    symptom: string;
  };
}

export interface BrowserNavigationEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.BrowserNavigation;
}

export interface SurveyOpenedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.SurveyOpened;
  properties: {
    surveyId: string;
    equipmentType: string;
  };
}

export interface SurveyDetailsProvidedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.SurveyDetailsProvided;
  properties: {
    question: string;
  };
}

export interface SurveyQuestionAnsweredEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.SurveyQuestionAnswered;
  properties: {
    question: string;
    secondsElapsed: number;
  };
}

export interface SurveyCompletedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.SurveyCompleted;
  properties: {
    surveyId: string;
    equipmentType: string;
    secondsElapsed: number;
  };
}

export interface SearchSuggestionClickedEvent extends BaseMixpanelEvent {
  name: MixpanelEventType.SearchSuggestionClicked;
  properties: {
    suggestion: string;
    equipmentType: string | null;
    question: string;
    sessionId: string;
    conversationId: string;
  };
}

export type MixpanelEvent =
  | EquipmentSelectedEvent
  | CustomerSelectedEvent
  | DocumentClickedEvent
  | RootCauseCopiedEvent
  | RootCausePastedEvent
  | DocumentSummaryCopiedEvent
  | CustomerSummaryCopiedEvent
  | ServiceHistorySymptomCopiedEvent
  | SimilarServiceJobsSymptomCopiedEvent
  | QuestionAskedEvent
  | OpenJobClickedEvent
  | CustomerOpenJobClickedEvent
  | BrowserNavigationEvent
  | SurveyOpenedEvent
  | SurveyDetailsProvidedEvent
  | SurveyQuestionAnsweredEvent
  | SurveyCompletedEvent
  | SearchSuggestionClickedEvent;
