import React from 'react';

import SmartLoopCustomerSearch from '../../../../Inputs/CustomerSearch/SmartLoopCustomerSearch';
import SmartLoopEquipmentSearch from '../../../../Inputs/EquipmentSearch/SmartLoopEquipmentSearch';
import SmartLoopQuestionSearch from '../../../../Inputs/QuestionSearch/SmartLoopQuestionSearch';
import { ActiveFilter, AssistantProps } from '../../../../../types';
import { useAppSelector } from '../../../../../store/utils/hooks';
import ActiveFilterToggle from '../../../../ActiveFilterToggle/ActiveFilterToggle';
import SearchExamples from '../SearchExamples/SearchExamples';
import styles from './HomeHeaderInputs.module.scss';

const HomeHeaderInputs: React.FC<AssistantProps> = (props) => {
  const { activeFilter, selectedEquipmentType, selectedTags } = useAppSelector(
    (state) => state.inputs,
  );

  return (
    <div>
      <div className={styles.inputBox}>
        <ActiveFilterToggle />
        {activeFilter === ActiveFilter.All && (
          <SmartLoopQuestionSearch
            {...props}
            equipmentType={selectedEquipmentType}
            allEquipmentTags={selectedTags.map((tag) => tag.fullName)}
          />
        )}
        {activeFilter === ActiveFilter.Customer && (
          <SmartLoopCustomerSearch {...props} />
        )}
        {activeFilter === ActiveFilter.EquipmentType && (
          <div>
            <SmartLoopEquipmentSearch {...props} />
            {selectedEquipmentType !== null && (
              <SmartLoopQuestionSearch
                {...props}
                equipmentType={selectedEquipmentType}
                allEquipmentTags={selectedTags.map((tag) => tag.fullName)}
              />
            )}
          </div>
        )}
      </div>
      <SearchExamples {...props} />
    </div>
  );
};

export default HomeHeaderInputs;
