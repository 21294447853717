import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AtLeast,
  BaseSessionProps,
  EquipmentConversationDto,
  EquipmentHistorySession,
  EquipmentSession,
  FeedbackType,
  FetchingStatus,
  Symptom,
  TroubleshootingStatus,
} from '../../types';
import { useAppSelector } from '../utils/hooks';
import { generateSymptom, isDefined } from '../../utils';
import { deleteSessions } from '../../integration/conversations.api';
import { ApiThunkParams, AppThunkConfig } from '../store';

export const CONVERSATIONS_KEY = 'conversations';

export interface ConversationsState {
  sessions: EquipmentSession[];
  focusedSessionId: string | null;
  status: FetchingStatus;
}

const initialState: ConversationsState = {
  sessions: [],
  focusedSessionId: null,
  status: FetchingStatus.IDLE,
};

export interface CreateSessionParams extends BaseSessionProps {
  question: string;
  equipmentType: string | null;
  allEquipmentTags: string[];
  troubleshootingOpen: boolean;
  isGenericSearch: boolean;
}

const generateEquipmentSession = ({
  conversationId,
  sessionId,
  question,
  equipmentType,
  allEquipmentTags,
  troubleshootingOpen,
  isGenericSearch,
}: CreateSessionParams): EquipmentSession => ({
  question,
  equipmentType,
  allEquipmentTags,
  conversationId,
  isGenericSearch,
  id: sessionId,
  intent: null,
  language: null,
  extractedSymptom: null,
  extractedSymptomStatus: FetchingStatus.PENDING,
  savedToHistory: false,
  createdAt: Date.now(),
  answer: null,
  answerJobIds: null,
  answerStatus: FetchingStatus.PENDING,
  parts: null,
  images: null,
  imagesStatus: FetchingStatus.PENDING,
  suggestions: null,
  suggestionsStatus: FetchingStatus.PENDING,
  jobs: null,
  jobsStatus: FetchingStatus.PENDING,
  feedback: {
    type: FeedbackType.NONE,
    open: false,
  },
  troubleshootingVisible: troubleshootingOpen,
  troubleshootingStatus: TroubleshootingStatus.Idle,
  troubleshootingSymptoms: [],
  troubleshootingSymptomsStatus: FetchingStatus.IDLE,
  troubleshootingSelectedSymptoms: [],
  troubleshootingRelatedSymptoms: null,
  diagnosisNodes: null,
  diagnosisStatus: FetchingStatus.IDLE,
  qnaSteps: null,
  resolvedRootCauses: null,
  rootCauses: null,
  openedRootCauseId: null,
  finalRootCause: null,
});

interface DeleteEquipmentSessionParams extends ApiThunkParams {
  ids: string[];
}

export const deleteEquipmentSession = createAsyncThunk<
  void,
  DeleteEquipmentSessionParams,
  AppThunkConfig
>(
  'deleteEquipmentSession',
  async ({ ids, baseUrl, mock, logError }, { signal }) => {
    await deleteSessions({
      ids,
      baseUrl,
      mock,
      signal,
      logError,
    });
  },
);

export interface AddRootCausePartialParams {
  sessionId: string;
  partial: string;
}

export interface UpdateEquipmentSessionTagsParams {
  sessionId: string;
  tags: string[];
}

export interface FinishEquipmentSessionParams {
  id: string;
  answer: string | null;
  parts: string[] | null;
}

// We need this for backward compatibility
const processTroubleshootingSelectedSymptoms = ({
  troubleshootingSelectedSymptoms,
  extractedSymptom,
  question,
}: EquipmentConversationDto): Symptom[] => {
  if (
    !isDefined(troubleshootingSelectedSymptoms) ||
    troubleshootingSelectedSymptoms.some(
      (symptom) => typeof symptom === 'string',
    )
  ) {
    return [generateSymptom(extractedSymptom || question)];
  } else {
    return troubleshootingSelectedSymptoms as Symptom[];
  }
};

const conversationsSlice = createSlice({
  name: CONVERSATIONS_KEY,
  initialState,
  reducers: {
    createEquipmentSession: (
      state,
      action: PayloadAction<CreateSessionParams>,
    ) => {
      return {
        ...state,
        focusedSessionId: action.payload.sessionId,
        sessions: [...state.sessions, generateEquipmentSession(action.payload)],
      };
    },
    updateEquipmentSession: (
      state,
      action: PayloadAction<AtLeast<EquipmentSession, 'id'>>,
    ) => {
      const updatedSession = action.payload;

      return {
        ...state,
        sessions: state.sessions.map((session) => {
          if (session.id === updatedSession.id) {
            return {
              ...session,
              ...updatedSession,
            };
          } else {
            return session;
          }
        }),
      };
    },
    updateEquipmentSessionTags: (
      state,
      action: PayloadAction<UpdateEquipmentSessionTagsParams>,
    ) => {
      const { sessionId, tags } = action.payload;
      const equipmentType = tags[0] || null;

      return {
        ...state,
        sessions: state.sessions.map((session) => {
          if (session.id === sessionId) {
            return {
              ...session,
              equipmentType:
                session.equipmentType === null
                  ? equipmentType
                  : session.equipmentType,
              allEquipmentTags: action.payload.tags,
            };
          } else {
            return session;
          }
        }),
      };
    },
    finishEquipmentSession: (
      state,
      action: PayloadAction<FinishEquipmentSessionParams>,
    ) => {
      const params = action.payload;

      return {
        ...state,
        sessions: state.sessions.map((session) => {
          if (session.id === params.id) {
            return {
              ...session,
              answer: params.answer,
              answerStatus: FetchingStatus.SUCCESS,
              parts: params.parts,
              images: session.images === null ? [] : session.images,
              imagesStatus:
                session.imagesStatus === FetchingStatus.PENDING
                  ? FetchingStatus.SUCCESS
                  : session.imagesStatus,
              jobs: session.jobs === null ? [] : session.jobs,
              jobsStatus:
                session.jobsStatus === FetchingStatus.PENDING
                  ? FetchingStatus.SUCCESS
                  : session.jobsStatus,
            };
          } else {
            return session;
          }
        }),
      };
    },
    setFocusedSession: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        focusedSessionId: action.payload,
      };
    },
    focusOnFirstSession: (state, action: PayloadAction<string>) => {
      const conversationId = action.payload;
      // This works only because new sessions are always added to the end of the list of sessions, their order is never changed
      const firstSession = state.sessions.find(
        (session) => session.conversationId === conversationId,
      );
      if (!isDefined(firstSession)) {
        return state;
      }

      return {
        ...state,
        focusedSessionId: firstSession.id,
      };
    },
    addRootCausePartial: (
      state,
      action: PayloadAction<AddRootCausePartialParams>,
    ) => {
      const { sessionId, partial } = action.payload;

      return {
        ...state,
        sessions: state.sessions.map((session) => {
          // Ignore stray parts arriving after the final answer
          if (
            session.id === sessionId &&
            session.answerStatus === FetchingStatus.PENDING
          ) {
            return {
              ...session,
              answer: partial,
            };
          } else {
            return session;
          }
        }),
      };
    },
    addEquipmentHistory: (
      state,
      action: PayloadAction<EquipmentHistorySession[]>,
    ) => {
      return {
        ...state,
        sessions: [
          ...state.sessions,
          ...action.payload.map(({ id, mediaFiles, content }) => {
            const freshImageUrls = new Map(
              mediaFiles.map((mf) => [mf.id, mf.link]),
            );
            const images =
              content.images !== null
                ? content.images.map((image) => ({
                    ...image,
                    url: freshImageUrls.get(image.id) || '',
                  }))
                : null;

            return {
              id,
              images,
              savedToHistory: true,
              conversationId: content.conversationId,
              createdAt: content.createdAt,
              question: content.question,
              equipmentType: content.equipmentType,
              intent: content.intent || null,
              isGenericSearch: content.isGenericSearch || false,
              language: content.language || null,
              allEquipmentTags: getDefaultEquipmentTags(
                content.equipmentType,
                content.allEquipmentTags,
              ),
              extractedSymptom: content.extractedSymptom || content.question,
              extractedSymptomStatus: FetchingStatus.SUCCESS,
              answer: processAnswerHistory(content),
              answerJobIds: content.answerJobIds || [],
              answerStatus: FetchingStatus.SUCCESS,
              parts: null,
              suggestions: content.suggestions,
              suggestionsStatus: FetchingStatus.SUCCESS,
              jobs: content.jobs,
              jobsStatus: FetchingStatus.SUCCESS,
              imagesStatus: FetchingStatus.SUCCESS,
              feedback: {
                type: content.feedback.type,
                open: false,
              },
              troubleshootingVisible: false,
              troubleshootingStatus:
                content.troubleshootingStatus || TroubleshootingStatus.Idle,
              troubleshootingSymptoms: [],
              troubleshootingSymptomsStatus: FetchingStatus.IDLE,
              troubleshootingSelectedSymptoms:
                processTroubleshootingSelectedSymptoms(content),
              diagnosisNodes: null,
              diagnosisStatus: FetchingStatus.IDLE,
              qnaSteps: null,
              resolvedRootCauses: null,
              rootCauses: null,
              openedRootCauseId: null,
              troubleshootingRelatedSymptoms: null,
              finalRootCause: content.finalRootCause || null,
            };
          }),
        ],
      };
    },
    setPendingEquipmentSessionsToError: (state) => {
      return {
        ...state,
        sessions: state.sessions.map((session) => {
          if (session.answerStatus === FetchingStatus.PENDING) {
            return {
              ...session,
              answerStatus: FetchingStatus.ERROR,
            };
          } else {
            return session;
          }
        }),
      };
    },
    reset: (state) => ({
      ...state,
      sessions: [],
      focusedSessionId: null,
    }),
  },
  extraReducers(builder) {
    builder.addCase(deleteEquipmentSession.pending, (state, action) => {
      const ids = new Set(action.meta.arg.ids);
      return {
        ...state,
        sessions: state.sessions.filter((session) => !ids.has(session.id)),
      };
    });
  },
});

const processAnswerHistory = ({
  answer,
  rootCause,
  documentSearchAnswer,
}: EquipmentConversationDto): string | null => {
  if (isDefined(answer)) {
    return answer;
  }
  if (isDefined(rootCause) || isDefined(documentSearchAnswer)) {
    return `${rootCause || ''}${documentSearchAnswer || ''}`;
  }
  return null;
};

const getDefaultEquipmentTags = (
  equipmentType: string | null,
  allEquipmentTags?: string[],
): string[] => {
  if (isDefined(allEquipmentTags)) {
    return allEquipmentTags;
  }
  if (equipmentType !== null) {
    return [equipmentType];
  }
  return [];
};

const { actions, reducer } = conversationsSlice;

export const {
  createEquipmentSession,
  updateEquipmentSession,
  updateEquipmentSessionTags,
  setFocusedSession,
  focusOnFirstSession,
  addRootCausePartial,
  addEquipmentHistory,
  finishEquipmentSession,
  setPendingEquipmentSessionsToError,
  reset: resetConversations,
} = actions;

export const useConversation = (id?: string): EquipmentSession[] => {
  const { sessions } = useAppSelector((state) => state.conversations);

  return sessions.filter((session) => session.conversationId === id);
};

export const conversationsReducer = reducer;
