import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ActiveFilter, Equipment, FetchingStatus } from '../../types';
import { ApiThunkParams, AppThunkConfig } from '../store';
import { fetchEquipmentTypes } from '../../integration/equipmentTypes.api';
import { fetchTrendingEquipments } from '../../integration/trendingEquipments.api';
import { Customer, fetchCustomers } from '../../integration/customers.api';
import { fetchTrendingCustomers } from '../../integration/trendingCustomers.api';

export const INPUTS_KEY = 'inputs';

export interface InputsState {
  equipmentSearchStatus: FetchingStatus;
  equipmentTypes: Equipment[];
  selectedEquipmentType: string | null;
  selectedTags: Equipment[];
  customerSearchStatus: FetchingStatus;
  customers: Customer[];
  question: string;
  activeFilter: ActiveFilter;
  activeFilterChanged: boolean;
  manualEquipmentSearchInitiated: boolean;
  manualCustomerSearchInitiated: boolean;
}

const initialState: InputsState = {
  equipmentSearchStatus: FetchingStatus.IDLE,
  equipmentTypes: [],
  selectedEquipmentType: null,
  selectedTags: [],
  customerSearchStatus: FetchingStatus.IDLE,
  customers: [],
  question: '',
  activeFilter: ActiveFilter.All,
  activeFilterChanged: false,
  manualEquipmentSearchInitiated: false,
  manualCustomerSearchInitiated: false,
};

interface SearchEquipmentParams extends ApiThunkParams {
  searchTerm: string;
}

export const searchEquipments = createAsyncThunk<
  Equipment[],
  SearchEquipmentParams,
  AppThunkConfig
>('searchEquipments', async ({ searchTerm, mock, baseUrl }, { signal }) => {
  return fetchEquipmentTypes({
    searchTerm,
    baseUrl,
    signal,
    mock,
  });
});

export const getTrendingEquipments = createAsyncThunk<
  Equipment[],
  ApiThunkParams,
  AppThunkConfig
>('getTrendingEquipments', async ({ mock, baseUrl }, { signal }) => {
  return fetchTrendingEquipments({
    baseUrl,
    signal,
    mock,
  });
});

export const getTrendingCustomers = createAsyncThunk<
  Customer[],
  ApiThunkParams,
  AppThunkConfig
>('getTrendingCustomers', async ({ mock, baseUrl }, { signal }) => {
  return fetchTrendingCustomers({
    baseUrl,
    signal,
    mock,
  });
});

interface SearchCustomerParams extends ApiThunkParams {
  searchTerm: string;
}

export const searchCustomers = createAsyncThunk<
  Customer[],
  SearchCustomerParams,
  AppThunkConfig
>('searchCustomers', async ({ searchTerm, mock, baseUrl }, { signal }) => {
  return fetchCustomers({
    baseUrl,
    searchTerm,
    signal,
    mock,
  });
});

interface SelectEquipmentTypesParams {
  selectedTags: Equipment[];
  selectedEquipmentType: string;
}

const inputsSlice = createSlice({
  name: INPUTS_KEY,
  initialState,
  reducers: {
    selectEquipmentTypes: (
      state,
      action: PayloadAction<SelectEquipmentTypesParams | null>,
    ) => {
      if (action.payload === null || action.payload.selectedTags.length === 0) {
        return {
          ...state,
          selectedEquipmentType: null,
          selectedTags: [],
        };
      }
      return {
        ...state,
        selectedEquipmentType: action.payload.selectedEquipmentType,
        selectedTags: action.payload.selectedTags,
      };
    },
    setQuestion: (state, action: PayloadAction<string>) => ({
      ...state,
      question: action.payload,
    }),
    setActiveFilter: (state, action: PayloadAction<ActiveFilter>) => ({
      ...state,
      activeFilter: action.payload,
      activeFilterChanged: true,
    }),
    setDefaultActiveFilter: (state, action: PayloadAction<ActiveFilter>) => ({
      ...state,
      activeFilter: action.payload,
    }),
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(searchEquipments.pending, (state) => {
        state.manualEquipmentSearchInitiated = true;
        state.equipmentSearchStatus = FetchingStatus.PENDING;
        state.equipmentTypes = [];
      })
      .addCase(searchEquipments.fulfilled, (state, action) => {
        state.equipmentSearchStatus = FetchingStatus.SUCCESS;
        state.equipmentTypes = action.payload;
      })
      .addCase(searchEquipments.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.equipmentSearchStatus = FetchingStatus.ERROR;
        state.equipmentTypes = [];
        state.selectedEquipmentType = null;
      })
      .addCase(searchCustomers.pending, (state) => {
        state.manualCustomerSearchInitiated = true;
        state.customerSearchStatus = FetchingStatus.PENDING;
        state.customers = [];
      })
      .addCase(searchCustomers.fulfilled, (state, action) => {
        state.customerSearchStatus = FetchingStatus.SUCCESS;
        state.customers = action.payload;
      })
      .addCase(searchCustomers.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled) {
          return;
        }
        state.customerSearchStatus = FetchingStatus.ERROR;
        state.customers = [];
      })
      .addCase(getTrendingEquipments.pending, (state) => {
        state.equipmentSearchStatus = FetchingStatus.PENDING;
      })
      .addCase(getTrendingEquipments.fulfilled, (state, action) => {
        if (state.manualEquipmentSearchInitiated) {
          return;
        } else {
          state.equipmentSearchStatus = FetchingStatus.SUCCESS;
          state.equipmentTypes = action.payload;
        }
      })
      .addCase(getTrendingEquipments.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled || state.manualEquipmentSearchInitiated) {
          return;
        }
        state.equipmentSearchStatus = FetchingStatus.ERROR;
      })
      .addCase(getTrendingCustomers.pending, (state) => {
        state.customerSearchStatus = FetchingStatus.PENDING;
      })
      .addCase(getTrendingCustomers.fulfilled, (state, action) => {
        if (state.manualCustomerSearchInitiated) {
          return;
        } else {
          state.customerSearchStatus = FetchingStatus.SUCCESS;
          state.customers = action.payload;
        }
      })
      .addCase(getTrendingCustomers.rejected, (state, action) => {
        const requestCancelled = action.meta.aborted;
        if (requestCancelled || state.manualCustomerSearchInitiated) {
          return;
        }
        state.customerSearchStatus = FetchingStatus.ERROR;
      });
  },
});

const { actions, reducer } = inputsSlice;

export const {
  selectEquipmentTypes,
  setQuestion,
  setActiveFilter,
  reset: resetInputs,
} = actions;

export const inputsReducer = reducer;
