import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { inputClasses } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import debounce from 'lodash.debounce';
import clsx from 'clsx';

import {
  ApiCall,
  FetchingStatus,
  SessionProps,
  Symptom,
  SymptomType,
} from '../../../../../../../types';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../assets/close.svg';
import { ReactComponent as SearchIcon } from '../../../../../../../assets/search.svg';
import { useAppDispatch } from '../../../../../../../store/utils/hooks';
import {
  generateSymptom,
  isDefined,
  useIsDarkMode,
  useTranslations,
} from '../../../../../../../utils';
import { updateEquipmentSession } from '../../../../../../../store/slices/conversations.slice';
import {
  searchSymptoms,
  SearchSymptomsParams,
} from '../../../../../../../store/actions/symptoms.actions';
import { getDiagnoses } from '../../../../../../../store/actions/diagnoses.actions';
import styles from './SymptomSearch.module.scss';

const SymptomSearch: React.FC<SessionProps> = (props) => {
  const { session, isMocking, httpUrl, logError } = props;
  const {
    equipmentType,
    troubleshootingSelectedSymptoms,
    troubleshootingRelatedSymptoms,
    troubleshootingSymptoms,
    troubleshootingSymptomsStatus,
    openedRootCauseId,
    qnaSteps,
  } = session;
  const darkMode = useIsDarkMode();
  const dispatch = useAppDispatch();
  const { assistant: translations } = useTranslations();
  const [inputFocused, setInputFocused] = useState(false);

  const handleChange = (_: SyntheticEvent, values: (string | Symptom)[]) => {
    const newSymptoms = values.map((value) => {
      if (typeof value === 'string') {
        return generateSymptom(value);
      } else {
        return value;
      }
    });
    dispatch(
      updateEquipmentSession({
        id: session.id,
        troubleshootingSelectedSymptoms: newSymptoms,
      }),
    );
  };
  const handleTagDeleted = (id: string) => {
    dispatch(
      updateEquipmentSession({
        id: session.id,
        troubleshootingSelectedSymptoms: troubleshootingSelectedSymptoms.filter(
          (symptom) => symptom.id !== id,
        ),
      }),
    );
  };
  const handleTagClicked = (symptom: Symptom) => {
    dispatch(
      updateEquipmentSession({
        id: session.id,
        troubleshootingSelectedSymptoms: [
          ...troubleshootingSelectedSymptoms,
          symptom,
        ],
      }),
    );
  };

  useEffect(() => {
    if (troubleshootingSelectedSymptoms.length === 0) {
      dispatch(
        updateEquipmentSession({
          id: session.id,
          diagnosisStatus: FetchingStatus.IDLE,
          diagnosisNodes: null,
          qnaSteps: null,
          resolvedRootCauses: null,
          rootCauses: null,
          openedRootCauseId: null,
        }),
      );
      return;
    }
    const promise = dispatch(
      getDiagnoses({
        logError,
        sessionId: session.id,
        baseUrl: httpUrl,
        mock: isMocking,
      }),
    );

    return () => {
      promise.abort();
    };
  }, [troubleshootingSelectedSymptoms, session.id, httpUrl, isMocking]);

  const [symptomsCall, setSymptomsCall] =
    useState<ApiCall<void, SearchSymptomsParams>>();
  const getOptions = useCallback(
    debounce((value: string) => {
      // Abort previous request
      if (isDefined(symptomsCall)) {
        symptomsCall.abort();
      }
      const searchTerm = value.trim();
      if (searchTerm.length >= 2 && equipmentType !== null) {
        const promise = dispatch(
          searchSymptoms({
            logError,
            equipmentType,
            sessionId: session.id,
            mock: isMocking,
            baseUrl: httpUrl,
            searchTerm: isMocking
              ? session.extractedSymptom || session.question
              : searchTerm,
          }),
        );
        setSymptomsCall(promise);
      }
    }, 300),
    [
      dispatch,
      symptomsCall,
      isMocking,
      httpUrl,
      session.id,
      session.extractedSymptom,
      session.question,
      equipmentType,
    ],
  );
  const handleInputChange = (_: React.SyntheticEvent, value: string) => {
    getOptions(value);
  };

  const processedRelatedSymptoms = useMemo(() => {
    if (!isDefined(troubleshootingRelatedSymptoms)) {
      return [];
    }
    return troubleshootingRelatedSymptoms
      .filter(
        (rs) =>
          !troubleshootingSelectedSymptoms.find((tss) => tss.name === rs.name),
      )
      .slice(0, 3);
  }, [troubleshootingRelatedSymptoms, troubleshootingSelectedSymptoms]);

  const noResultsFound =
    troubleshootingSymptomsStatus === FetchingStatus.SUCCESS &&
    troubleshootingSymptoms.length === 0;
  const disabled = qnaSteps !== null && qnaSteps.length > 1;

  if (openedRootCauseId !== null) {
    return null;
  }

  return (
    <>
      {!disabled && (
        <p className={styles.label}>
          {translations.troubleshooting.symptomSelectorLabel}
        </p>
      )}
      <Autocomplete
        multiple
        freeSolo
        className={clsx(styles.symptomsInput, disabled && styles.disabled)}
        options={troubleshootingSymptoms}
        disabled={disabled}
        value={troubleshootingSelectedSymptoms}
        onChange={handleChange}
        onInputChange={handleInputChange}
        loading={troubleshootingSymptomsStatus === FetchingStatus.PENDING}
        loadingText={translations.filterLoading}
        noOptionsText={noResultsFound && translations.filterNoResults}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.name
        }
        filterOptions={(x) => x}
        renderTags={(tagValues) => (
          <div className={styles.tags}>
            {tagValues.map((tag) => (
              <div
                key={tag.id}
                className={clsx(
                  styles.tag,
                  darkMode && styles.darkMode,
                  getSymptomTypeClass(tag.type),
                )}
              >
                <span>{tag.name}</span>
                <DeleteIcon
                  className={styles.deleteIcon}
                  onClick={() => handleTagDeleted(tag.id)}
                />
              </div>
            ))}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            sx={{
              [`& .${inputClasses.root}`]: {
                paddingBottom: '12px',
              },
              [`& .${inputClasses.underline}:after`]: {
                borderBottomColor: '#808080',
              },
            }}
            placeholder={
              disabled
                ? ''
                : translations.troubleshooting.symptomSearchPlaceholder
            }
            onFocus={() => setInputFocused(true)}
            onBlur={() => setInputFocused(false)}
            InputProps={{
              ...params.InputProps,
              disableUnderline: disabled,
              startAdornment: (
                <>
                  {params.InputProps.startAdornment}
                  {!inputFocused && !disabled && (
                    <InputAdornment position="start">
                      <SearchIcon className={styles.searchIcon} />
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        )}
      />
      {processedRelatedSymptoms.length > 0 && !disabled && (
        <div className={styles.relatedSymptoms}>
          <div className={styles.relatedSymptomsLabel}>
            {translations.troubleshooting.relatedSymptoms}
          </div>
          <div className={styles.symptomList}>
            {processedRelatedSymptoms.map((rs) => (
              <div
                key={rs.id}
                className={clsx(
                  styles.relatedSymptom,
                  getSymptomTypeClass(rs.type),
                )}
                onClick={() => handleTagClicked(rs)}
              >
                <span>{rs.name}</span>
                <PlusIcon className={styles.plusIcon} />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

const getSymptomTypeClass = (type: SymptomType): string | null => {
  if (type === SymptomType.Dynamic) {
    return styles.fromDiagnosis;
  }
  if (type === SymptomType.Static) {
    return styles.fromFailurePattern;
  }
  return null;
};

export default SymptomSearch;
